import {translate} from 'sulu-admin-bundle/utils';
import {AbstractListToolbarAction} from 'sulu-admin-bundle/views';

export default class UnpublishArticlesToolbarAction extends AbstractListToolbarAction {
    getToolbarItemConfig() {
        const {disable_for_empty_selection: disableForEmptySelection = false} = this.options;

        return {
            type: 'button',
            label: translate('app.unpublish_articles'),
            icon: 'su-pen',
            disabled: disableForEmptySelection && this.listStore.selections.length === 0,
            onClick: this.handleClick,
        };
    }

    handleClick = () => {
        if (this.listStore.selections.length === 0) {
            alert(translate('app.no_articles_selected'));

            return;
        }


        const selectedIds = this.listStore.selections
            .filter(item => item.publishedState === true)
            .map((item) => item.id)
            .join(',');

            if (confirm(translate('app.unpublish_confirm_msg')) == true) {
                fetch('/admin/api/articles/unpublishes', {
                    headers: { "Content-Type": "application/json; charset=utf-8" },
                    method: 'POST',
                    body: JSON.stringify({
                        ids: selectedIds,
                        currentUrl :window.location.href
                    })
                })
                .then(response => response.json())
                .then(response => response[0].code === "200" ? location.reload() : alert(response[0].message))
              } 

    };
}

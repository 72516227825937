// Add project specific javascript code and import of additional bundles here:
import {listToolbarActionRegistry} from 'sulu-admin-bundle/views';
import UnpublishArticlesToolbarAction from "./listToolbarActions/UnpublishArticlesToolbarAction";

listToolbarActionRegistry.add('app.unpublish_articles', UnpublishArticlesToolbarAction);


    const runfunc = (e)=>{
      let champ1 = document.getElementById('/description_image')
      let champ2 = document.getElementById('/copyright_image')
      let image = document.querySelector('.single-item-selection---kA-JYVira')
      if(e.target.checked){
        champ1.value=""
        champ2.value=""
        if (image.querySelector('.su-trash-alt')){
          image.querySelector('.su-trash-alt').click()
        }
      }
    }
    setInterval(()=>{
      let link = window.location.href
      let champ1 = document.getElementById('/description_image')
      if(link.includes('articles')&&link.includes('article')&&link.includes('details')){
      
      let checkbox = document.querySelector('input[type=checkbox]')
      if(champ1!=null){
      checkbox.addEventListener('click',runfunc)}
     }},1000)



injectCssFile('/build/admin-overrides.css');

function injectCssFile(name) {
  const style = document.createElement('link');
  const head = document.querySelector('head');
  
  style.setAttribute('href', name);
  style.setAttribute('rel', 'stylesheet');
  
  head.append(style);
}